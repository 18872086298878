@font-face {
  font-family: "Montserrat Regular";
  src: local("Montserrat Regular"), url(./assets/fonts/Montserrat-Regular.ttf)format('truetype');
}

@font-face {
  font-family: "Montserrat Medium";
  src: local("Montserrat Medium"), url(./assets/fonts/Montserrat-Medium.ttf)format('truetype');
}

body {
  font-family: "Montserrat Regular", "Helvetica Neue", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #fafafa;
  box-sizing: border-box;
  padding: 0;
  margin: 0 !important;
}

::-webkit-scrollbar {
  width: 0.4rem;
}

::-webkit-scrollbar:horizontal {
  height: 0.4rem;
}

/* Track */
::-webkit-scrollbar-track,
::-webkit-scrollbar-track:horizontal {
  background: #f1f1f1; 
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb,
::-webkit-scrollbar-thumb:horizontal {
  background: rgb(196, 194, 194);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover,
::-webkit-scrollbar-thumb:horizontal:hover {
  background: rgb(196, 194, 194); 
}

.MuiTypography-body1 {
  font-size: 1rem !important;
}

#root {
  height: 100vh;
}
